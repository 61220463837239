import { gql } from "@apollo/client";
let LIVE = Number(process.env.REACT_APP_LIVE);
let api_base_url, admin_panel_name;
if (LIVE == 1) {
	api_base_url = process.env.REACT_APP_LIVE_API_URL;
	admin_panel_name = process.env.REACT_APP_LIVE__NAME;
} else {
	api_base_url = process.env.REACT_APP_LOCAL_API_URL;
	admin_panel_name = process.env.REACT_APP_LOCAL_NAME;
}
export const API_URL = api_base_url;
export const ADMIN_PANEL_NAME = admin_panel_name;

export const API_PATH = {
	login: API_URL + "user/login",
	UploadImages: API_URL + "user/upload-file",
	getAllProduct: API_URL + "user/get-all-product",
	getAllUser: API_URL + "user/get-all-user",
	AddProduct: API_URL + "user/upsert-product",
};

export const GQL_QUERY = {
	GetCollectionData: gql`
		{
			collections(first: 250) {
				edges {
					node {
						id
						title
					}
				}
			}
		}
	`,
};
