import React from "react";
import MainRoutes from "./MainRoutes";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/css/style.scss";
import { ToastContainer } from "react-toastify";
import { RoleStore } from "./contexts/roleContext";

export default function App() {
	return (
		<RoleStore>
			<ToastContainer autoClose={1000} theme="dark" position="bottom-left" />
			<MainRoutes />
		</RoleStore>
	);
}
