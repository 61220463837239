import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "./tablestyle.scss";

export default function RtdDatatable(props) {
	const [option, set_option] = useState(props.option);
	const [data, set_Data] = useState(props.data);
	const [columns, set_columns] = useState(props.columns);
	const [size_per_page, set_size_per_page] = useState([10, 25, 50, 100, 250]);
	const [RowClick, set_RowClick] = useState(props.needRowClick);

	useEffect(() => {
		set_option(props.option);
		set_Data(props.data);
		set_columns(props.columns);
		set_size_per_page([10, 25, 50, 100, 250]);
	}, [props.option, props.data, props.columns]);

	const tableCall = (e) => {
		let value = e.target.value;
		let name = e.target.name;
		let tmp_option = option;
		if (name === "search") {
			setTimeout(() => {
				tmp_option[name] = value;
				tmp_option["page"] = 1;
				set_option(tmp_option);
				props.tableCallBack(tmp_option);
			}, 1000);
		} else {
			if (value !== "") {
				tmp_option[name] = parseInt(value);
				tmp_option["page"] = 1;
				set_option(tmp_option);
				props.tableCallBack(tmp_option);
			}
		}
	};

	const sortHandler = (field) => {
		let tmp_option = option;
		if (field === tmp_option["sort"]) {
			tmp_option["order"] === "DESC" ? (tmp_option["order"] = "ASC") : (tmp_option["order"] = "DESC");
		} else {
			tmp_option["order"] = "ASC";
			tmp_option["sort"] = field;
		}
		set_option(tmp_option);
		props.tableCallBack(tmp_option);
	};

	const handlePageChange = (pageNumber) => {
		let tmp_option = option;
		tmp_option["page"] = pageNumber["selected"] + 1;
		set_option(tmp_option);
		props.tableCallBack(tmp_option);
	};

	return (
		option && (
			<div className="table-outer-border-class">
				<div className="row align-items-center custom-table-hdr text-center">
					<div className="col-md-3 d-md-block d-flex align-items-center justify-content-center">
						<label className="d-flex align-items-center custom-select-label">
							Show
							<select name="sizePerPage" className="form-select mx-2" defaultValue={option.sizePerPage} onChange={tableCall}>
								{size_per_page.map((val, i) => {
									return (
										<option key={i} value={val}>
											{val}
										</option>
									);
								})}
							</select>
							entries
						</label>
					</div>
					<div className="col-md-6 ms-auto pt-3 pt-md-0">
						<div className="position-relative seacrh-icon-right">
							<input type="search" name="search" className="form-control" onChange={tableCall} placeholder="Search ..." />
							<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7.95473 1C6.57921 1 5.23459 1.40787 4.09089 2.17204C2.94719 2.93622 2.05579 4.02236 1.5294 5.29313C1.00301 6.5639 0.865287 7.96222 1.13364 9.31126C1.40199 10.6603 2.06436 11.8995 3.03699 12.8721C4.00963 13.8447 5.24884 14.507 6.59793 14.7754C7.94701 15.0437 9.34538 14.906 10.6162 14.3796C11.887 13.8533 12.9732 12.9619 13.7374 11.8182C14.5016 10.6746 14.9095 9.32997 14.9095 7.9545C14.9093 6.11009 14.1766 4.34125 12.8723 3.03706C11.5681 1.73286 9.7992 1.00012 7.95473 1V1Z" stroke="#5e5873" strokeWidth="1.875" strokeMiterlimit="10" />
								<path d="M13.143 13.1431L18.0001 18" stroke="#5e5873" strokeWidth="1.875" strokeMiterlimit="10" strokeLinecap="round" />
							</svg>
						</div>
					</div>
				</div>
				<div className="table-responsive table-custom-info rtd-table-main-div">
					<table className="table mb-0">
						<thead>
							<tr>
								{columns?.map((column, i) => {
									return column.options["sort"] ? (
										<th key={i} className={column.options.class ? column.options.class : ""} onClick={() => sortHandler(column.value)}>
											{column.label}
											{column.value !== option["sort"] ? (
												<>
													<button type="button" className="border-0 bg-transparent p-0 sorting-top">
														<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M5 0.5L10 5.5H0L5 0.5Z" fill="#5E5873" />
														</svg>
													</button>
													<button type="button" className="border-0 bg-transparent p-0 sorting-bottom">
														<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M5 5.5L10 0.5H0L5 5.5Z" fill="#5E5873" />
														</svg>
													</button>
												</>
											) : column.value === option["sort"] && option["order"] === "ASC" ? (
												<button type="button" className="border-0 bg-transparent p-0 sorting-top">
													<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M5 0.5L10 5.5H0L5 0.5Z" fill="#5E5873" />
													</svg>
												</button>
											) : (
												<button type="button" className="border-0 bg-transparent p-0 sorting-bottom">
													<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M5 5.5L10 0.5H0L5 5.5Z" fill="#5E5873" />
													</svg>
												</button>
											)}
										</th>
									) : (
										<th key={i} className={column.options.class ? column.options.class : ""}>
											{column.label}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{data?.length > 0 ? (
								data.map((val, i) => {
									let no = i + 1 + option.sizePerPage * (option.page - 1);
									return (
										<tr
											key={i}
											onClick={() => {
												RowClick?.click && RowClick.callback(val);
											}}
											style={RowClick?.click && { cursor: "pointer" }}
										>
											{columns.map((col, index) => {
												return (
													<td key={index} className={col.options.class ? col.options.class : ""}>
														{col.options.customBodyRender ? col.options.customBodyRender(data, i, no) : data[i][col.value]}
													</td>
												);
											})}
										</tr>
									);
								})
							) : (
								<tr className="p-md-5 p-3 m-md-5 text-center">
									<td colSpan={columns.length}>
										<p>Sorry, no matching records found</p>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				<div>
					{option.totalRecord > 0 && (
						<div className="d-md-flex align-items-center custom-table-btm text-center">
							<div className="text-md-start">
								<div className="custom-table-page">
									Showing {parseInt((option.page - 1) * option.sizePerPage + 1)} to {parseInt((option.page - 1) * option.sizePerPage + option.sizePerPage) > option.totalRecord ? option.totalRecord : (option.page - 1) * option.sizePerPage + option.sizePerPage} of {option.totalRecord} entries
								</div>
							</div>
							<div className="pt-3 pt-md-0 ms-md-auto text-md-end text-center">
								<div className="pagination-custom-info">
									<ReactPaginate
										className="pagination d-flex justify-content-md-end justify-content-center"
										pageClassName="page-item"
										activeClassName="active"
										breakLabel="..."
										breakLinkClassName="page-link"
										breakClassName="page-item"
										previousLabel={
											<span aria-hidden="true">
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M7.99988 12L3.99988 8L7.99988 4" stroke="#27272E" strokeWidth="1.34513" strokeLinecap="round" strokeLinejoin="round" />
													<path opacity="0.4" d="M12.6666 12L8.66656 8L12.6666 4" stroke="#8492A6" strokeWidth="1.34513" strokeLinecap="round" strokeLinejoin="round" />
												</svg>
											</span>
										}
										previousClassName="page-item"
										nextLabel={
											<span aria-hidden="true">
												<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M8 12L12 8L8 4" stroke="#27272E" strokeWidth="1.34513" strokeLinecap="round" strokeLinejoin="round" />
													<path opacity="0.4" d="M3.3335 12L7.3335 8L3.3335 4" stroke="#8492A6" strokeWidth="1.34513" strokeLinecap="round" strokeLinejoin="round" />
												</svg>
											</span>
										}
										nextClassName="page-item"
										pageLinkClassName="page-link"
										pageRangeDisplayed={3}
										onPageActive={option.page - 1}
										pageCount={option.totalRecord / option.sizePerPage}
										renderOnZeroPageCount={null}
										onPageChange={handlePageChange.bind()}
										forcePage={option.page - 1}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		)
	);
}
