import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GQL_QUERY } from "../api/ApiName";
import { gql_fetch_data } from "../api/apiServices";

const Context = React.createContext("");

export function RoleStore(props) {
	let [user_detail, set_user_detail] = useState({});
	let [vivid_collection_data, set_vivid_collection_data] = useState([]);
	let [user_role, set_user_role] = useState();

	useEffect(() => {
		get_vivid_store_data();
		set_user_roll();
	}, []);

	useEffect(() => {
		if (user_role == undefined) {
			set_user_roll();
		}
	}, [user_role]);

	const set_user_roll = async () => {
		let token = sessionStorage.getItem("vividreacttoken");
		if (token !== null) {
			let userRole = await parseJwt(token);
			set_user_role(userRole.role);
		}
	};

	const parseJwt = (token) => {
		return new Promise((resolve, reject) => {
			var base64Url = token.split(".")[1];
			var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
			var jsonPayload = decodeURIComponent(
				window
					.atob(base64)
					.split("")
					.map(function (c) {
						return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join("")
			);
			resolve(JSON.parse(jsonPayload));
		});
	};

	const change_user_role = (user_role) => {
		set_user_role(user_role);
	};

	const set_login_user_details = (user_detail) => {
		set_user_detail(user_detail);
	};

	const set_vivid_collection_all_data = (vivid_collection_data) => {
		set_vivid_collection_data(vivid_collection_data);
	};

	const get_vivid_store_data = () => {
		const GetBlogByIDDataPromise = new Promise((resolve, reject) => {
			resolve(gql_fetch_data(GQL_QUERY.GetCollectionData, {}));
		});
		GetBlogByIDDataPromise.then((response) => {
			if (response.success === true) {
				let temp_collection = [];
				response.data.data.collections.edges.map((val) => {
					temp_collection.push({ value: val.node.id.split("/").at(-1), label: val.node.title });
				});
				set_vivid_collection_data(temp_collection);
			} else {
				toast.error(response.message);
			}
		});
	};

	return <Context.Provider value={{ ...{ user_detail, vivid_collection_data, user_role }, set_login_user_details, parseJwt, set_vivid_collection_all_data, change_user_role }}>{props.children}</Context.Provider>;
}

export default Context;
