import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Placeholder_image from "../images/placeholder.svg";
import { PostApi } from "../api/apiServices";
import { API_PATH } from "../api/ApiName";
import Select from "react-select";
import roleContext from "../contexts/roleContext";
import moment from "moment";
import RtdDatatable from "../DataTable/RtdDatatable";
import loder_data from "../Loader/loader.json";
import Lottie from "react-lottie-player";

export default function Product() {
	const context = useContext(roleContext);
	const runforms = useRef();
	const edit_runforms = useRef();
	const [loader, set_loader] = useState(false);
	const [show_img_modal, set_show_img_modal] = useState(false);
	const [show_original_img, set_show_original_img] = useState("");
	const [img_change, set_img_change] = useState(3);
	const [userRole, set_userRole] = useState();
	const [selectedstatus, set_selectedstatus] = useState(0);
	const [add_product_modal, set_add_product_modal] = useState(false);
	const [edit_product_modal, set_edit_product_modal] = useState(false);
	const [image, set_image] = useState("");
	const [product_data, set_product_data] = useState([]);
	const [edit_product_data, set_edit_product_data] = useState([]);
	const [selectedCategoryOption, set_selectedCategoryOption] = useState([]);
	const [category_options, set_category_options] = useState([]);
	const image_quality_option = [
		{ name: "Hight-clearity", value: "[1, 1.33, 1.66, 2.0, 2.66, 4, 5]" },
		{ name: "Low-clearity", value: "[1, 1.16, 1.33, 1.5, 1.66, 1.83, 2]" },
	];
	const [option, set_option] = useState({
		sizePerPage: 10,
		search: "",
		totalRecord: 0,
		page: 1,
		sort: "id",
		order: "ASC",
	});
	const [columns, setColumns] = useState([
		{
			value: "id",
			label: "No",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (data, i, no) => {
					return <div>{no}</div>;
				},
			},
		},
		{
			value: "image",
			label: "Product Image",
			options: {
				filter: false,
				sort: false,
				class: "cust-img-width",
				customBodyRender: (data, i) => {
					return <img width="50" height="50" src={data[i].img_link} alt={data[i].product_nameð} />;
				},
			},
		},
		{
			value: "created_at",
			label: "Publish Date",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (data, i) => {
					return moment(data[i]).format("DD-MM-YYYY");
				},
			},
		},
		{
			value: "product_name",
			label: "Product Name",
			options: {
				filter: true,
				sort: true,
				class: "set-column-width",
			},
		},
		{
			value: "description",
			label: "Description",
			options: {
				filter: true,
				sort: false,
			},
		},
		{
			value: "status",
			label: "Status",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (data, i) => {
					return data[i].status == 0 ? "NOT STARTED" : data[i].status == 1 ? "RUNNING" : data[i].status == 2 ? "FINISHED" : data[i].status == 3 ? "DESIGNER SUBMIT" : data[i].status == 4 ? "SEO REJECTED" : data[i].status == 5 ? "EDIT REQUIRED" : "NOT ASSIGN";
				},
			},
		},
	]);

	useEffect(() => {
		get_all_product_Data(option);
	}, []);

	useEffect(() => {
		if (show_img_modal === true) {
			set_show_original_img(image);
		} else {
			set_show_original_img("");
		}
	}, [show_img_modal]);

	useEffect(() => {
		set_userRole(context.user_role);
	}, [context.user_role]);

	useEffect(() => {
		set_category_options(context.vivid_collection_data);
	}, [context.vivid_collection_data]);

	let errorContainer = (form, field) => {
		return form.touched[field] && form.errors[field] ? <span className="error text-danger">{form.errors[field]}</span> : null;
	};

	let formAttr = (form, field) => ({ onBlur: form.handleBlur, onChange: form.handleChange, value: form.values[field] });

	const file_upload = (e) => {
		let formData = new FormData();
		formData.append("file", e.target.files[0]);
		const FileUploadDataPromise = new Promise((resolve, reject) => {
			resolve(PostApi(API_PATH.UploadImages, formData));
		});
		FileUploadDataPromise.then((response) => {
			if (response.status === 200) {
				set_image(URL.createObjectURL(e.target.files[0]));
				if (add_product_modal == true) {
					runforms.current.setFieldValue("img_link", response.data.data.url);
				}
				if (edit_product_modal == true) {
					edit_runforms.current.setFieldValue("img_link", response.data.data.url);
				}
			} else {
				toast.error(response.data.message);
			}
		});
	};

	useEffect(() => {
		if (edit_product_modal == true) {
			edit_releted_category(edit_product_data);
		}
	}, [edit_product_modal]);

	const edit_releted_category = (data) => {
		let temp_selected_Category = [];
		if (category_options.length > 0) {
			for (let i = 0; i < category_options.length; i++) {
				if (data.collection_ids.includes(category_options[i].value)) {
					temp_selected_Category.push(category_options[i]);
				}
			}
			set_selectedCategoryOption(temp_selected_Category);
		}
	};

	const category_handleChange = (value) => {
		let temp_category = [];
		let temp_category_name = [];
		for (let i = 0; i < value.length; i++) {
			temp_category.push(value[i].value);
			temp_category_name.push(value[i].label);
		}
		if (add_product_modal == true) {
			runforms.current.setFieldValue("collection_ids", temp_category.toString());
			runforms.current.setFieldValue("collection_names", temp_category_name.toString());
		}
		if (edit_product_modal == true) {
			edit_runforms.current.setFieldValue("collection_ids", temp_category.toString());
			edit_runforms.current.setFieldValue("collection_names", temp_category_name.toString());
		}
		set_selectedCategoryOption(value);
	};

	useEffect(() => {
		if (add_product_modal == false) {
			set_image("");
			runforms.current?.setFieldValue("collection_ids", "");
			runforms.current?.setFieldValue("collection_names", "");
			runforms.current?.setFieldValue("img_link", "");
			set_selectedCategoryOption([]);
		}
	}, [add_product_modal]);

	useEffect(() => {
		if (edit_product_modal == false) {
			set_image("");
			edit_runforms.current?.setFieldValue("collection_ids", "");
			edit_runforms.current?.setFieldValue("collection_names", "");
			edit_runforms.current?.setFieldValue("img_link", "");
			set_selectedCategoryOption([]);
		}
	}, [edit_product_modal]);

	const handleSubmitAddProduct = (formData, resetForm) => {
		set_loader(true);
		const AddProductDataPromise = new Promise((resolve, reject) => {
			resolve(PostApi(API_PATH.AddProduct, formData));
		});
		AddProductDataPromise.then((response) => {
			set_loader(false);
			if (response.status === 200) {
				resetForm(formData);
				set_add_product_modal(false);
				get_all_product_Data(option);
				toast.success(response.data.message);
			} else {
				toast.error(response.message);
			}
		});
	};

	useEffect(() => {
		get_all_product_Data(option);
	}, [selectedstatus]);

	const get_all_product_Data = (option) => {
		set_loader(true);
		let data = { status: selectedstatus, ...option };
		const GetAllProductDataPromise = new Promise((resolve, reject) => {
			resolve(PostApi(API_PATH.getAllProduct, data));
		});
		GetAllProductDataPromise.then((response) => {
			set_loader(false);
			if (response.status === 200) {
				set_product_data(response.data.data);
				set_option({ ...option, totalRecord: response.data.totalRecord });
			} else {
				toast.error(response.message);
			}
		});
	};

	const get_product_detail_by_id = (value) => {
		set_edit_product_data(value);
		set_image(value.img_link);
		set_edit_product_modal(true);
	};

	const handleSubmitEditProduct = (formData, resetForm) => {
		set_loader(true);
		const GetProductDataByIdPromise = new Promise((resolve, reject) => {
			resolve(PostApi(API_PATH.AddProduct, formData));
		});
		GetProductDataByIdPromise.then((response) => {
			set_loader(false);
			if (response.status === 200) {
				resetForm(formData);
				set_edit_product_modal(false);
				get_all_product_Data(option);
				toast.success(response.data.message);
			} else {
				toast.error(response.message);
			}
		});
	};

	const RowClick = { click: true, callback: get_product_detail_by_id };

	let tableCallBack = (option) => {
		set_option(option);
		get_all_product_Data(option);
	};

	const reject_product_send = (id) => {
		set_loader(true);
		let formData = { id: id, status: 4 };
		const GetProductDataByIdPromise = new Promise((resolve, reject) => {
			resolve(PostApi(API_PATH.AddProduct, formData));
		});
		GetProductDataByIdPromise.then((response) => {
			set_loader(false);
			if (response.status === 200) {
				set_edit_product_modal(false);
				get_all_product_Data(option);
				toast.success(response.data.message);
			} else {
				toast.error(response.message);
			}
		});
	};

	const delete_product_send = (id) => {
		set_loader(true);
		let formData = { id: id, status: 6 };
		const GetProductDataByIdPromise = new Promise((resolve, reject) => {
			resolve(PostApi(API_PATH.AddProduct, formData));
		});
		GetProductDataByIdPromise.then((response) => {
			set_loader(false);
			if (response.status === 200) {
				set_edit_product_modal(false);
				get_all_product_Data(option);
				toast.success(response.data.message);
			} else {
				toast.error(response.message);
			}
		});
	};

	const change_handle_view = (value) => {
		if (value === true) {
			set_img_change(5);
		} else {
			set_img_change(3);
		}
	};

	const image_modal_show = () => {
		if (image !== "") {
			set_show_img_modal(true);
		}
	};

	return (
		<>
			{loader && (
				<div className="loader loader-dateset-main">
					<Lottie loop animationData={loder_data} play />
				</div>
			)}
			<Layout>
				<div className="content-main-section">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="comn-inr-title d-flex align-items-center">
									<h2>Product List</h2>
									{userRole == 2 && (
										<>
											<div className="ms-auto">
												<select className="form-control frnt-input-style form-select" name="company" onChange={(e) => set_selectedstatus(Number(e.target.value))}>
													<option value={0}>Filter by Image</option>
													<option value={3}> Change Image List</option>
													<option value={5}> Not Change Image List </option>
												</select>
											</div>
											<div className="ms-3">
												<button onClick={() => set_add_product_modal(true)} type="button" className="btn-smart-comn">
													Add New
												</button>
											</div>
										</>
									)}
								</div>
							</div>
							<div className="col-12">
								<div className="table-custom-info">{userRole && <RtdDatatable data={product_data} columns={columns} option={option} tableCallBack={tableCallBack} needPagination={true} needRowClick={(userRole == 3 || userRole == 2) && RowClick} />}</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
			{/* Add Product modal */}
			{add_product_modal && (
				<Modal show={add_product_modal} onHide={() => set_add_product_modal(false)} size="xl" centered>
					<Modal.Header closeButton>
						<Modal.Title>Add New Product</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Formik
							innerRef={runforms}
							enableReinitialize={true}
							initialValues={{
								product_name: "",
								img_link: "",
								description: "",
								collection_ids: "",
								collection_names: "",
								status: img_change,
								multiplier: "[1, 1.33, 1.66, 2.0, 2.66, 4, 5]",
							}}
							validationSchema={Yup.object({
								product_name: Yup.string()
									.matches(/^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/, "Only alphabets and number and dash and space are allowed for this field ")
									.required("product is required."),
								img_link: Yup.string().required("Image is required."),
								multiplier: Yup.string().required("Image Clearity is required."),
								collection_ids: Yup.string().required("Category is required."),
							})}
							onSubmit={(formData, { resetForm }) => {
								handleSubmitAddProduct(formData, resetForm);
							}}
						>
							{(runform) => (
								<form className="row me-0" onSubmit={runform.handleSubmit}>
									<div className="form-group col-8 pe-0">
										<label className="lbl-frnt-side mb-2">Product Name</label>
										<input type="text" name="product_name" {...formAttr(runform, "product_name")} className="form-control frnt-input-style" placeholder="Enter product name" />
										{errorContainer(runform, "product_name")}
									</div>
									<div className="form-group col-2 pe-0">
										<label className="lbl-frnt-side mb-2">Image Quality</label>
										<select className="form-control frnt-input-style" name="multiplier" {...formAttr(runform, "multiplier")}>
											{image_quality_option.map((val, i) => {
												return (
													<option key={i} defaultValue={val.value == "[1, 1.33, 1.66, 2.0, 2.66, 4, 5]"} value={val.value}>
														{val.name}
													</option>
												);
											})}
										</select>
										{errorContainer(runform, "multiplier")}
									</div>
									<div className="form-group col-2 d-flex flex-column align-items-center pe-0">
										<label className="lbl-frnt-side mb-2">If Change Image</label>
										<div className="form-check form-switch">
											<input className="form-check-input" name="img_change" type="checkbox" onChange={(e) => change_handle_view(e.target.checked)} value={img_change} />
										</div>
									</div>
									<div className="form-group col-12 pe-0">
										<label className="lbl-frnt-side mb-2">Description</label>
										<input type="text" name="description" placeholder="Enter description" className="form-control frnt-input-style" {...formAttr(runform, "description")}></input>
									</div>
									<div className="form-group col-6 mt-3 pe-0">
										<label className="lbl-frnt-side mb-2">Category Name</label>
										<Select isMulti className={"form-control"} value={selectedCategoryOption} onChange={category_handleChange} options={category_options} />
										{errorContainer(runform, "collection_ids")}
									</div>
									<div className="form-group col-6 mt-3 pe-0">
										<label className="lbl-frnt-side mb-2">Product image</label>
										<input accept="image/jpg" type="file" name="img_link" onChangeCapture={(e) => file_upload(e)} className="form-control frnt-input-style file-line" placeholder="Enter image" />
										<div className="d-flex align-items-center justify-content-center img-cursor-pointer" onClick={() => image_modal_show()}>
											<img className="blog-image img-fluid mt-3" style={{ width: "400px", height: "400px" }} src={image !== "" ? image : Placeholder_image} alt="image" />
										</div>
										{errorContainer(runform, "img_link")}
									</div>
									<div className="col-12 mt-3 text-center">
										<button type="submit" className="btn-smart-comn px-4">
											Add New Product
										</button>
									</div>
								</form>
							)}
						</Formik>
					</Modal.Body>
				</Modal>
			)}
			{/* Edit Product modal */}
			{edit_product_modal && (
				<Modal show={edit_product_modal} onHide={() => set_edit_product_modal(false)} size="xl" centered>
					<Modal.Header closeButton>
						<Modal.Title>Edit Product</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Formik
							innerRef={edit_runforms}
							initialValues={{
								id: edit_product_data ? edit_product_data.id : "",
								product_name: edit_product_data ? edit_product_data.product_name : "",
								description: edit_product_data ? edit_product_data.description : "",
								collection_ids: edit_product_data ? edit_product_data.collection_ids : "",
								collection_names: edit_product_data ? edit_product_data.collection_names : "",
								img_link: image,
								multiplier: edit_product_data ? edit_product_data.multiplier : "",
								status: userRole == 3 ? 0 : userRole == 2 && 3,
							}}
							validationSchema={Yup.object({
								product_name: Yup.string()
									.matches(/^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/, "Only alphabets and number and dash and space are allowed for this field ")
									.required("product is required."),
								collection_ids: Yup.string().required("Category is required."),
							})}
							onSubmit={(formData, { resetForm }) => {
								handleSubmitEditProduct(formData, resetForm);
							}}
						>
							{(runform) => (
								<form className="row me-0" onSubmit={runform.handleSubmit}>
									<div className="form-group col-5 pe-0">
										<label className="lbl-frnt-side mb-2">Product Name</label>
										<input type="text" name="product_name" {...formAttr(runform, "product_name")} className="form-control frnt-input-style" placeholder="Enter product name" />
										{errorContainer(runform, "product_name")}
									</div>
									<div className="form-group col-2 pe-0">
										<label className="lbl-frnt-side mb-2">Image Quality</label>
										<select className="form-control frnt-input-style" name="multiplier" {...formAttr(runform, "multiplier")}>
											{image_quality_option.map((val, i) => {
												return (
													<option key={i} defaultValue={val.value == edit_product_data.multiplier} value={val.value}>
														{val.name}
													</option>
												);
											})}
										</select>
										{errorContainer(runform, "multiplier")}
									</div>
									<div className="form-group col-5 pe-0">
										<label className="lbl-frnt-side mb-2">Description</label>
										<input type="text" name="description" placeholder="Enter description" className="form-control frnt-input-style" {...formAttr(runform, "description")}></input>
									</div>
									<div className="form-group col-6 mt-3 pe-0">
										<label className="lbl-frnt-side mb-2">Category Name</label>
										<Select isMulti className={"form-control"} value={selectedCategoryOption} onChange={category_handleChange} options={category_options} />
										{errorContainer(runform, "collection_ids")}
									</div>
									<div className="form-group col-6 mt-3 pe-0">
										<label className="lbl-frnt-side mb-2">Product image</label>
										{userRole == 2 && <input accept="image/jpg" type="file" name="img_link" onChangeCapture={(e) => file_upload(e)} className="form-control frnt-input-style file-line mb-3" placeholder="Enter image" />}
										<div className="d-flex align-items-center justify-content-center img-cursor-pointer" onClick={() => image_modal_show()}>
											<img className="blog-image img-fluid" style={{ width: "400px", height: "400px" }} src={image !== "" ? image : Placeholder_image} alt="image" />
										</div>
										{errorContainer(runform, "img_link")}
									</div>
									<div className="col-12 mt-3 text-center">
										{userRole == 2 && (
											<>
												<button type="submit" className="btn-smart-comn px-4 me-2">
													Update
												</button>
											</>
										)}
										{userRole == 3 && (
											<>
												<button type="submit" className="btn-smart-comn px-4 me-2">
													Approve
												</button>
												<button type="submit" onClick={() => delete_product_send(edit_product_data.id)} className="btn-smart-comn cancel-btn px-4 me-2">
													Delete
												</button>
												<button type="reset" onClick={() => reject_product_send(edit_product_data.id)} className="btn-smart-comn reject-btn px-4 me-2">
													Reject
												</button>
											</>
										)}
										<button type="reset" onClick={() => set_edit_product_modal(false)} className="btn-smart-comn cancel-btn px-4">
											Cancel
										</button>
									</div>
								</form>
							)}
						</Formik>
					</Modal.Body>
				</Modal>
			)}
			{/* View Original Product Image modal */}
			{show_img_modal && (
				<Modal show={show_img_modal} onHide={() => set_show_img_modal(false)} size="xl">
					<Modal.Header closeButton>
						<Modal.Title>View Product Image</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-12">
								<div className="d-flex align-items-center justify-content-center">
									<img className="blog-image img-fluid" src={show_original_img} alt="image" />
								</div>
							</div>
							<div className="col-12 mt-3 text-center">
								<button type="reset" onClick={() => set_show_img_modal(false)} className="btn-smart-comn cancel-btn px-4">
									close
								</button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}
