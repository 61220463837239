import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SideLogoIcn from "../images/side-logo-icn.svg";
import ArrowCheck from "../images/arrow-check-icon.svg";
import ArrowCheckRemove from "../images/arrow-check-close.svg";

function Sidebar() {
	const Navigate = useNavigate();
	const location = useLocation();

	const handleLogout = () => {
		sessionStorage.removeItem("vividreacttoken");
		Navigate("/login");
	};

	const idarrowdesk = () => {
		document.getElementById("root").classList.add("dash-main-class-add");
	};

	const idarrowdeskremove = () => {
		document.getElementById("root").classList.remove("dash-main-class-add");
	};

	return (
		<React.Fragment>
			<div className="sidebar-main-section">
				<div className="brand-title d-flex align-items-center">
					<div className="d-inline-flex align-items-center">
						<Link to="/dashboard" className="d-inline-flex align-items-center cursor-pointer">
							<div className="d-inline-flex align-items-center">
								<img src={SideLogoIcn} alt="logo" />
								<span>VIVID WALLS</span>
							</div>
						</Link>
					</div>
					<div className="ms-auto position-relative ps-2 d-xl-block d-none">
						<img src={ArrowCheck} className="close-arrow-show" alt="arrow" onClick={() => idarrowdesk()} id="idarrowdesk" />
						<img src={ArrowCheckRemove} className="close-arrow-hide" onClick={() => idarrowdeskremove()} id="idarrowdeskremove" alt="arrow" />
					</div>
				</div>
				<div className="sidebar-main-section-inner">
					<ul className="sidebar-menu-boxes">
						<li>
							<Link to="/dashboard" className={location.pathname === "/dashboard" ? "active" : ""}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-speedometer2" viewBox="0 0 16 16">
									<path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
									<path fillRule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z" />
								</svg>
								<span>Dashboard</span>
							</Link>
						</li>
						<li>
							<Link to="/user" className={location.pathname === "/user" ? "active" : ""}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
									<path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
								</svg>
								<span>User</span>
							</Link>
						</li>
						<li>
							<Link to="/collection" className={location.pathname === "/collection" ? "active" : ""}>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-collection" viewBox="0 0 16 16">
									<path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13z" />
								</svg>
								<span>Collection</span>
							</Link>
						</li>
						<li>
							<Link to="/product" className={location.pathname === "/product" ? "active" : ""}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-diagram-3" viewBox="0 0 16 16">
									<path fillRule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
								</svg>
								<span>Product</span>
							</Link>
						</li>
					</ul>
					<div className="sidebar-log-fix">
						<button type="submit" className="border-0 bg-transparent" onClick={() => handleLogout()}>
							<svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path d="M19.5558 0H2.44421C1.79597 0 1.17427 0.257515 0.715894 0.715894C0.257515 1.17427 0 1.79597 0 2.44421L0 7.33368H2.44421V2.44421H19.5558V19.5558H2.44421V14.6674H0V19.5558C0 20.204 0.257515 20.8257 0.715894 21.2841C1.17427 21.7425 1.79597 22 2.44421 22H19.5558C20.204 22 20.8257 21.7425 21.2841 21.2841C21.7425 20.8257 22 20.204 22 19.5558V2.44421C22 1.79597 21.7425 1.17427 21.2841 0.715894C20.8257 0.257515 20.204 0 19.5558 0V0Z" fill="#6C6A81"></path>
								<path d="M8.65974 15.3823L10.3892 17.1118L16.5003 11.0007L10.3892 4.88965L8.65974 6.61911L11.8187 9.77911H0V12.2233H11.8187L8.65974 15.3823Z" fill="#6C6A81"></path>
							</svg>
							<span>Log Out</span>
						</button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Sidebar;
