import React from "react";
import { BrowserRouter, Route, Routes, Navigate, Outlet } from "react-router-dom";
import CollectionData from "./pages/CollectionData";
import Login from "./pages/Common/Login";
import Dashboard from "./pages/Dashboard";
import Product from "./pages/Product";
import User from "./pages/User";

function RequireAuth() {
	let isAuthenticated = sessionStorage.getItem("vividreacttoken");
	return isAuthenticated !== null && isAuthenticated !== undefined && isAuthenticated !== "" ? <Outlet /> : <Navigate to={"/login"} />;
}

export default function MainRoutes() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" strict element={<Login />} />
				<Route path="/login" strict element={<Login />} />

				<Route element={<RequireAuth />}>
					<Route path="/dashboard" strict element={<Dashboard />} />
					<Route path="/user" strict element={<User />} />
					<Route path="/collection" strict element={<CollectionData />} />
					<Route path="/product" strict element={<Product />} />
				</Route>

				<Route path="*" element={<Navigate to={"/login"} />} />
			</Routes>
		</BrowserRouter>
	);
}
