import React, { useContext, useEffect, useState } from "react";
import ArrowCheck from "../images/arrow-check-icon.svg";
import ArrowCheckRemove from "../images/arrow-check-close.svg";
import Profile from "../images/profile-image.png";
import { ADMIN_PANEL_NAME } from "../api/ApiName";
import roleContext from "../contexts/roleContext";

function Header() {
	const context = useContext(roleContext);
	const [userRole, set_userRole] = useState();

	useEffect(() => {
		set_userRole(context.user_role);
	}, [context.user_role]);

	const idarrowdesk = () => {
		document.getElementById("root").classList.add("dash-main-class-add");
	};

	const idarrowdeskremove = () => {
		document.getElementById("root").classList.remove("dash-main-class-add");
	};
	return (
		<React.Fragment>
			<header className="header-fix-top-section">
				<div className="d-xl-none pe-3">
					<img src={ArrowCheck} className="close-arrow-show" alt="arrow" onClick={() => idarrowdesk()} id="idarrowdesk" />
					<img src={ArrowCheckRemove} className="close-arrow-hide" onClick={() => idarrowdeskremove()} id="idarrowdeskremove" alt="arrow" />
				</div>
				<div>
					<b>{ADMIN_PANEL_NAME}</b>
				</div>
				<div className="ms-auto hdr-user-div d-flex align-items-center">
					<div>
						<img src={Profile} alt="profile" />
					</div>
					<span className="ms-3">{userRole == 1 ? "Super Admin" : userRole == 2 ? "Designer" : userRole == 3 ? "Seo User" : "User"}</span>
				</div>
			</header>
		</React.Fragment>
	);
}

export default Header;
