import React from 'react'
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'

export default function Layout(props) {


    const removeLayer = () => {
        document.getElementById("root").classList.remove("dash-main-class-add");
    };
    return (
        <>
            <div id="dash-wrapper" className="dash-wrapper admn-cust-style-new">
                <main className="content-wrapper-section">
                    <Header />
                    <Sidebar />
                    {props.children}
                    <Footer />
                    <div className="overlay toggle-icon-main" onClick={removeLayer}></div>
                </main>
            </div>
        </>
    )
}
