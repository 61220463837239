import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import Lottie from "react-lottie-player";
import loder_data from "../Loader/loader.json";
import RtdDatatable from "../DataTable/RtdDatatable";
import roleContext from "../contexts/roleContext";

export default function CollectionData() {
	const context = useContext(roleContext);
	const [loader, set_loader] = useState(false);
	const [collection_data, set_collection_data] = useState([]);
	const [option, set_option] = useState({ sizePerPage: 250, search: "", totalRecord: 0, page: 1, sort: "id", order: "DESC" });
	const [columns, setColumns] = useState([
		{
			value: "value",
			label: "No",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (data, i, no) => {
					return <div>{no}</div>;
				},
			},
		},
		{
			value: "value",
			label: "ID",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			value: "label",
			label: "Collection",
			options: {
				filter: true,
				sort: true,
			},
		},
	]);

	useEffect(() => {
		set_collection_data(context.vivid_collection_data);
	}, []);

	let tableCallBack = (option) => {
		set_option(option);
	};

	return (
		<>
			{loader && (
				<div className="loader loader-dateset-main">
					<Lottie loop animationData={loder_data} play />
				</div>
			)}
			<Layout>
				<div className="content-main-section">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="comn-inr-title d-flex align-items-center">
									<h2>Plugin List</h2>
								</div>
							</div>
							<div className="col-12">
								<div className="table-custom-info">
									<RtdDatatable data={collection_data} columns={columns} option={option} tableCallBack={tableCallBack} needPagination={true} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
}
