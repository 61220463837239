import React, { useState } from "react";
import Layout from "../components/Layout";

export default function Dashboard() {
	return (
		<Layout>
			<div className="content-main-section">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<div className="comn-inr-title d-flex align-items-center">
								<h2>Dashboard</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}
