import React, { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import SideImg from "../../images/loginbanner.png";
import LoginImg from "../../images/login-img.png";
import LeftLogo from "../../images/mainlogo.png";
import { API_PATH, GQL_QUERY } from "../../api/ApiName";
import { gql_fetch_data, PostApi } from "../../api/apiServices";
import roleContext from "../../contexts/roleContext";

const sign = require("jwt-encode");
const secret = "^HkNZ*AqT$1Nyi_1zVf)pzp0i7y6kz#Uf4%sxs!s4Ae&G5u";

export default function Login() {
	const context = useContext(roleContext);
	const Navigate = useNavigate();
	const [loader, set_loader] = useState(false);
	const [remember, setremember] = useState(false);
	const [email, setemail] = useState("");
	const [password, setpassword] = useState("");
	const [showPassword, setshowPassword] = useState("password");

	useEffect(() => {
		let remember_me_token = localStorage.getItem("remember_token");
		if (remember_me_token !== null) {
			let temp = jwt_decode(remember_me_token);
			setremember(true);
			setemail(temp.email);
			setpassword(temp.password);
		}
	}, []);

	const encode_token_jwt = (formData) => {
		var date = new Date();
		var time = date.getTime();
		const data = { exp: time + 3600, email: formData.email, password: formData.password, iat: time };
		const jwt = sign(data, secret);
		if (remember) {
			localStorage.setItem("remember_token", jwt);
		}
	};

	const LoginFormData = (formData, resetForm) => {
		set_loader(true);
		const LoginPromise = new Promise((resolve, reject) => {
			resolve(PostApi(API_PATH.login, formData));
		});
		LoginPromise.then((response) => {
			set_loader(false);
			if (response.status == 200) {
				sessionStorage.setItem("vividreacttoken", response.data.data.token);
				let userRole = context.parseJwt(response.data.data.token);
				context.change_user_role(userRole.role);
				encode_token_jwt(formData);
				resetForm(formData);
				toast.success(response.data.message);
				get_vivid_store_data();
			} else {
				toast.error(response.data.message);
			}
		});
	};

	const get_vivid_store_data = () => {
		set_loader(true);
		const GetBlogByIDDataPromise = new Promise((resolve, reject) => {
			resolve(gql_fetch_data(GQL_QUERY.GetCollectionData, {}));
		});
		GetBlogByIDDataPromise.then((response) => {
			set_loader(false);
			if (response.success === true) {
				let temp_collection = [];
				response.data.data.collections.edges.map((val) => {
					temp_collection.push({ value: val.node.id.split("/").at(-1), label: val.node.title });
				});
				context.set_vivid_collection_all_data(temp_collection);
				Navigate("/dashboard");
			} else {
				toast.error(response.message);
			}
		});
	};

	const errorContainer = (form, field) => {
		return form.touched[field] && form.errors[field] ? <span className="error text-danger">{form.errors[field]}</span> : null;
	};

	const formAttr = (form, field) => ({
		onBlur: form.handleBlur,
		onChange: form.handleChange,
		value: form.values[field],
	});

	const showpassword = () => {
		if (showPassword === "password") {
			setshowPassword("text");
		} else {
			setshowPassword("password");
		}
	};

	const handelCheckbox = (e) => {
		if (e.target.checked) {
			setremember(true);
		} else {
			setremember(false);
			localStorage.removeItem("remember_token");
		}
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-6 p-0 d-lg-block position-relative d-none">
					<div className="login-side-img ">
						<img src={SideImg} alt="login" className="img-fluid" />
						<div className="side-login-logo">
							<img src={LeftLogo} alt="login" className="img-fluid" />
						</div>
					</div>
				</div>
				<div className="col-lg-6 login-form-side">
					<div className="login-main-area">
						<div className="login-main-part-scroll">
							<div className="login-main-side">
								<div className="m-auto login-main-box user-sign-form white-bx-info">
									<div className="w-100 text-center mb-5">
										<img src={LoginImg} alt="logo" className="img-fluid login-main-img" />
									</div>
									<div className="login-text-top pb-2">
										<h1>Sign In</h1>
										<p>Enter email and password to login your account.</p>
									</div>
									<Formik
										enableReinitialize
										initialValues={{
											email: email ? email : "",
											password: password ? password : "",
										}}
										validationSchema={Yup.object({
											email: Yup.string().email("Enter valid Email address.").required("Email is required."),
											password: Yup.string().required("Password is required."),
										})}
										onSubmit={(formData, { resetForm }) => {
											LoginFormData(formData, resetForm);
										}}
									>
										{(runform) => (
											<form className="row" onSubmit={runform.handleSubmit}>
												<div className="col-12 mb-3">
													<label className="form-lbl-class mb-2">Email Address</label>
													<bdi className="d-block position-relative icn-position">
														<input type="email" {...formAttr(runform, "email")} className="form-control style-input-class ps-5" name="email" placeholder="Johndoe@gmail.com" />
														<svg width="16" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z" fill="#1A202C" />
														</svg>
													</bdi>
													{errorContainer(runform, "email")}
												</div>
												<div className="col-12 mb-3">
													<label className="form-lbl-class mb-2">Password</label>
													<bdi className="d-block  password-class">
														<div className="position-relative">
															<bdi className="icn-position">
																<input id="toggle-pass" type={showPassword} {...formAttr(runform, "password")} className="form-control style-input-class ps-5" name="password" placeholder="Enter your new password" />
																<svg width="16" height="16" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM5 5C5 3.34 6.34 2 8 2C9.66 2 11 3.34 11 5V7H5V5ZM14 19H2V9H14V19ZM8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16Z" fill="#1A202C" />
																</svg>
															</bdi>
															<span id="eye-pwd" className="eye-pwd bg-transparent" onClick={showpassword}>
																{showPassword === "text" ? (
																	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
																		<path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
																		<path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
																	</svg>
																) : (
																	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
																		<path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
																		<path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
																	</svg>
																)}
															</span>
														</div>
													</bdi>
													{errorContainer(runform, "password")}
												</div>
												<div className="col-6 mb-3">
													<div className="custom-checkbox">
														<label className="custom-lbl-part" htmlFor="rememberme">
															<input type="checkbox" id="rememberme" checked={remember} onChange={handelCheckbox} />
															<span className="custom-checkbox-class"></span>
															Remember Me
														</label>
													</div>
												</div>
												{/* <div className="col-6 mb-3 text-end">
													<div className="d-inline-block form-link-style">
														<span>Forgot Password ?</span>
													</div>
												</div> */}
												<div className="col-12 mt-3 text-center">
													<button type="submit" className="btn-smart-comn w-100">
														Sign In
													</button>
												</div>
											</form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
