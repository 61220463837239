import axios from "axios";
import { ApolloClient, InMemoryCache } from "@apollo/client";

export function GetApi(path) {
	let token_Data = "";
	if (sessionStorage.getItem("vividreacttoken")) {
		token_Data = "Bearer " + sessionStorage.getItem("vividreacttoken");
	}
	let headers = { Authorization: token_Data };
	const GetApiData = axios
		.get(path, { headers: headers })
		.then((response) => {
			return response;
		})
		.catch((err) => {
			console.log(err);
			if (err.response.data.message === "Not Authorized or invalid token.") {
				sessionStorage.removeItem("vividreacttoken");
				window.location.href = "/";
			}
			return err.response;
		});
	return GetApiData;
}

export function PostApi(path, body) {
	let token_Data = "";
	if (sessionStorage.getItem("vividreacttoken")) {
		token_Data = "Bearer " + sessionStorage.getItem("vividreacttoken");
	}
	let headers = { Authorization: token_Data };
	const PostApiData = axios
		.post(path, body, { headers: headers })
		.then((response) => {
			return response;
		})
		.catch((err) => {
			if (err.response.data.message === "Not Authorized or invalid token.") {
				sessionStorage.removeItem("vividreacttoken");
				window.location.href = "/";
			}
			return err.response;
		});
	return PostApiData;
}

export const gql_fetch_data = async (query, variables) => {
	let platform_url = process.env.REACT_APP_LIVE_CATEGORY_URL + "api/2022-10/graphql.json";
	let platform_token = process.env.REACT_APP_LIVE_CATEGORY_TOKEN;
	try {
		const apolloClient = new ApolloClient({
			uri: platform_url,
			cache: new InMemoryCache(),
			headers: {
				"X-Shopify-Storefront-Access-Token": platform_token ?? "",
			},
		});
		const result = await apolloClient.query({
			query,
			variables,
		});
		return {
			success: true,
			message: "Data fetched.",
			data: result,
		};
	} catch (error) {
		return {
			success: false,
			message: "error caught.",
			error: error,
		};
	}
};
