import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { toast } from "react-toastify";
import { PostApi } from "../api/apiServices";
import { API_PATH } from "../api/ApiName";
import moment from "moment";
import RtdDatatable from "../DataTable/RtdDatatable";
import loder_data from "../Loader/loader.json";
import Lottie from "react-lottie-player";

export default function User() {
	const [user_data, set_user_data] = useState([]);
	const [loader, set_loader] = useState(false);
	const [option, set_option] = useState({
		sizePerPage: 10,
		search: "",
		totalRecord: 0,
		page: 1,
		sort: "created_at",
		order: "DESC",
	});
	const [columns, setColumns] = useState([
		{
			value: "id",
			label: "No",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (data, i, no) => {
					return <div>{no}</div>;
				},
			},
		},
		{
			value: "name",
			label: "User Name",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (data, i) => {
					return <img width="50" height="50" src={data[i].img_link} alt={data[i].product_nameð} />;
				},
			},
		},
		{
			value: "created_at",
			label: "Publish Date",
			options: {
				filter: true,
				sort: false,
				customBodyRender: (data, i) => {
					return moment(data[i]).format("DD-MM-YYYY");
				},
			},
		},
		{
			value: "email",
			label: "Email",
			options: {
				filter: true,
				sort: true,
			},
		},
	]);

	useEffect(() => {
		// get_all_user_Data(option);
	}, []);

	const get_all_user_Data = (option) => {
		const GetAllProductDataPromise = new Promise((resolve, reject) => {
			resolve(PostApi(API_PATH.getAllUser, option));
		});
		GetAllProductDataPromise.then((response) => {
			if (response.status === 200) {
				set_user_data(response.data.data);
				set_option({ ...option, totalRecord: response.data.totalRecord });
			} else {
				toast.error(response.message);
			}
		});
	};

	let tableCallBack = (option) => {
		set_option(option);
		// get_all_user_Data(option);
	};

	return (
		<>
			{loader && (
				<div className="loader loader-dateset-main">
					<Lottie loop animationData={loder_data} play />
				</div>
			)}
			<Layout>
				<div className="content-main-section">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="comn-inr-title d-flex align-items-center">
									<h2>User List</h2>
								</div>
							</div>
							<div className="col-12">
								<div className="table-custom-info">
									<RtdDatatable data={user_data} columns={columns} option={option} tableCallBack={tableCallBack} needPagination={true} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
}
